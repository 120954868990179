import { Link } from "gatsby"
import React from "react"
import InnerHeader from "../../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import * as dealerLocatorStyles from "./dealer-locator-form.module.scss"

const DealerLocatorForm = ({ pageContext, location }) => {
  const pageTitle = "Dealer Locator"

  return (
    <div>
      <Layout>
        <Seo pageTitle={pageTitle} />
        <InnerHeader
          pageTitle={pageTitle}
          pageContext={pageContext}
          location={location}
        />
        <div
          className={`inner-container-alt inner-y-padding ${dealerLocatorStyles.contentWrapper}`}
        >
          <div style={{ marginBottom: `3.5rem` }}>
            <h2 style={{ color: "#9d5918", textDecoration: `underline` }}>
              Dealers
            </h2>
            <h3
              className="h5"
              style={{
                maxWidth: "50rem",
                marginBottom: "3rem",
                lineHeight: "1.3",
              }}
            >
              Are you a retailer of Rocky Brands products looking to have your
              store location listed on our online dealer locator? Do you need to
              make a modification to an existing location? Please view the
              guidelines below:
            </h3>
            <ul className="bulleted-list">
              <li>Must have $5000 in YTD invoiced sales to be listed</li>
              <li>Must have a store location with a physical address</li>
              <li>
                Accounts active as online only will not meet the qualification
                guidelines
              </li>
            </ul>
          </div>
          <div style={{ marginBottom: `3.5rem` }}>
            <h2 style={{ color: "#9d5918", textDecoration: `underline` }}>
              Consumers
            </h2>
            <h3
              className="h5"
              style={{
                maxWidth: "50rem",
                marginBottom: "3rem",
                lineHeight: "1.3",
              }}
            >
              Are you a consumer looking for a store location to purchase one of
              our Brands Products? Unfortunately, you won’t find that here.
              Fortunately, we can send you to our brands page. Once you choose a
              brand, you can find the dealer locator link at the top of the
              page, or you can buy directly online.
            </h3>
            <div>
              <Link to="/brands" title="View Brands" className="CTAblack">
                View Brands
              </Link>
            </div>
          </div>
          <div style={{ marginBottom: `3.5rem` }}>
            <h2 style={{ color: "#9d5918", textDecoration: `underline` }}>
              Dealer Online Locator Instructions:
            </h2>
            <p
              style={{
                maxWidth: "50rem",
                marginBottom: "3rem",
                lineHeight: "1.3",
              }}
            >
              <span
                style={{
                  fontWeight: `900`,
                  fontSize: `1.3rem`,
                  paddingRight: "0.5rem",
                }}
              >
                NOTICE:
              </span>{" "}
              As of 12/1/2013 we have modified our process for providing Dealer
              Address location information.
              <br />
              <br />
              To ensure we receive all the required information, we have created
              a simple MS Excel Template for you to complete and upload below.
              To ensure your request is filled in a timely manner, please ensure
              you have reviewed the Help Tab contained in the Template file and
              have provided all data indicated as required.
            </p>
            <div>
              <a
                href="/docs/dealer-resources/dealer-locator/Rocky-Brands-Store-Location-Template-Master.xls"
                title="Download Template"
                className={`CTAblack ${dealerLocatorStyles.excelCTA}`}
              >
                Download Excel Template
              </a>
            </div>
            <small>
              Please allow 30 business days for your request to be reviewed for
              approval and processed. All request will be published monthly once
              approved.
            </small>
          </div>
          {/* FORM GROUPS*/}
          <div className="form-container">
            <form
              name="dealer-locator-form"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              enctype="multipart/form-data"
            >
              <input
                type="hidden"
                name="form-name"
                value="dealer-locator-form"
              ></input>
              {/* company name */}
              <div className="form-group">
                <label htmlFor="company-name">Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="company-name"
                  name="company-name"
                  placeholder="Company Name"
                  required
                />
              </div>
              {/* store location template */}
              <div className="form-group">
                <label
                  htmlFor="store-location-template"
                  className="file-upload__label__text"
                >
                  Store Location Template
                </label>
                <input
                  type="file"
                  className="file-upload"
                  id="store-location-template"
                  name="store-location-template"
                  placeholder="Store Location Template"
                  required
                />

                <small>
                  Only valid and completed data templates will be reviewed.
                </small>
              </div>
              {/* brands included checkboxes */}
              <fieldset
                className="form-group"
                style={{ marginBottom: `2.8rem`, marginTop: `2rem` }}
              >
                <legend htmlFor="brands-included">
                  <strong>Brands Included</strong>
                </legend>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="brands-included-rocky-boots"
                      name="brands-included"
                      value="Rocky Boots"
                    />
                    <span>Rocky Boots</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="brands-included-georgia-boot"
                      name="brands-included"
                      value="Georgia Boot"
                    />
                    <span>Georgia Boot</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="brands-included-durango-boots"
                      name="brands-included"
                      value="Durango Boots"
                    />
                    <span>Durango Boots</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="brands-included-the-original-muck-boot-co"
                      name="brands-included"
                      value="The Original Muck Boot Co"
                    />
                    <span>The Original Muck Boot Co</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="brands-included-xtratuf"
                      name="brands-included"
                      value="XTRATUF"
                    />
                    <span>XTRATUF</span>
                  </label>
                </div>
              </fieldset>
              {/* email */}
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required
                />
              </div>
              {/* phone */}
              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                  required
                />
              </div>
              {/* additional brands */}
              <fieldset
                className="form-group"
                style={{ marginBottom: `2rem`, marginTop: `2rem` }}
              >
                <legend htmlFor="additional-brands">
                  <strong>Tell me more about additional Brands?</strong>
                </legend>
                <div>
                  <label className="radio-alt">
                    <input
                      type="radio"
                      id="additional-brands-yes"
                      name="additional-brands"
                      value="Yes"
                      required
                    />
                    <span>Yes</span>
                  </label>
                </div>
                <div>
                  <label className="radio-alt">
                    <input
                      type="radio"
                      id="additional-brands-no"
                      name="additional-brands"
                      value="No"
                      required
                    />
                    <span>No</span>
                  </label>
                </div>
              </fieldset>
              {/* Data Exchange opportunities */}
              <fieldset
                className="form-group"
                style={{ marginBottom: `2.8rem`, marginTop: `2rem` }}
              >
                <legend htmlFor="data-exchange-opportunities">
                  <strong>
                    I'd like to know more about Data Exchange opportunities
                    selling online
                  </strong>
                </legend>
                <div>
                  <label className="radio-alt">
                    <input
                      type="radio"
                      id="data-exchange-opportunities-yes"
                      name="data-exchange-opportunities"
                      value="Yes"
                      required
                    />
                    <span>Yes</span>
                  </label>
                </div>
                <div>
                  <label
                    className="radio-alt"
                    htmlFor="data-exchange-opportunities-no"
                  >
                    <input
                      className="radio-input"
                      type="radio"
                      id="data-exchange-opportunities-no"
                      name="data-exchange-opportunities"
                      value="No"
                      required
                    />
                    <span>No</span>
                  </label>
                </div>
              </fieldset>

              {/* submit button */}
              <div>
                <input className="CTAblack" type="submit" value="Submit" />
              </div>
            </form>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default DealerLocatorForm
